<template>
  <div>
    <div class="page-title dashboard">
      <div class="container">
        <div class="row pb-3">
          <div class="col-12 col-md-6">
            <div class="page-title-content">
              <p>
                Welcome Back!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="card sub-menu">
              <div class="card-body">
                <ul class="d-flex">
                  <li
                    v-for="link in links"
                    :key="link.to"
                    class="nav-item"
                    :class="$route.name === link.title ? 'active' : null"
                  >
                    <router-link
                      :to="link.to"
                      class="nav-link"
                    >
                      <i :class="link.icon"></i>
                      <span>{{ link.text }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="card profile_card">
              <div class="card-body">
                <div class="media flex-wrap">
                  <div class="media-body col-12">
                    <p class="mb-1">
                      <span><i class="fa fa-phone mr-2 text-primary"></i></span>
                      +1 {{ userData && userData.phone_number.slice(1) }}
                    </p>
                    <p v-if="userData && userData.email" class="mb-1">
                      <span
                        ><i class="fa fa-envelope mr-2 text-primary"></i
                      ></span>
                      {{ userData.email }}
                    </p>
                    <p align="right" class="mb-1">
                      <a href="https://hub.getcoins.com" target="_blank">
                        <span
                          >edit account settings
                          <i class="fa fa-edit mr-2 text-primary"></i></span
                      ></a>
                    </p>
                  </div>
                </div>

                <ul class="card-profile__info">
                  <li>
                    <h5 class="text-danger mr-4">Last Login</h5>
                    <span class="text-danger">{{ lastLoginDate }}</span>
                  </li>
                  <li class="mt-4">
                    <div class="form-group mb-0">
                      <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="darkMode" />
                        <span class="toggle-switch"></span>
                        <span class="toggle-label">Toggle Dark Mode</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="card acc_balance">
              <div class="card-header">
                <h4 class="card-title"></h4>
              </div>
              <div class="card-body">
                <span>Available Balance | <small v-if="depositsLoaded && deposits !== null ">expires {{ expiryDate }}</small></span>
                <h1 class="text-primary">${{ balance }}</h1>

                <div class="d-flex justify-content-between my-3 flex-wrap">
                  <div class="col-12 col-md-6 my-2 pl-0">
                    <p class="mb-1">Last Fiat buy</p>
                    <h4>$ {{ fiatBuy }}</h4>
                  </div>
                  <div class="col-12 col-md-6 my-2 pl-0 pl-md-3">
                    <p class="mb-1">Total ₿ buy this month</p>
                    <h4>{{ totalCrypto }} BTC</h4>
                  </div>
                </div>
                <p align="left" class="mb-1">
                  <router-link to="trade">
                    <span
                      >Click here to start a trade
                      <i class="fas fa-wallet mr-2 text-primary"></i
                    ></span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Latest Transactions History</h4>
              </div>
              <div class="card-body">
                <div class="transaction-table">
                  <Table :length="5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import client from '@/api/client';
import user from '@/api/user';
import Table from '@/components/TransactionTable.vue';

export default {
  components: { Table },
  data: () => ({
    darkMode: false,
    links: [
      {
        to: 'profile',
        title: 'Profile',
        icon: 'mdi mdi-bullseye',
        text: 'Account Overview',
      },
      {
        to: 'settings-account',
        title: 'SettingsAccount',
        icon: 'mdi mdi-bank',
        text: 'Linked Bank Accounts',
      },
      {
        to: 'settings-crypto',
        title: 'SettingsCrypto',
        icon: 'mdi mdi-bank',
        text: 'Linked Crypto Wallets',
      },
      {
        to: 'settings',
        title: 'SettingsCrypto',
        icon: 'mdi mdi-lock',
        text: 'VIP Application',
      },
    ],
    deposits: [],
    depositsLoaded: false,
    accounts: [],
    trades: [],
    balance: 0,
    userData: null,
  }),
  computed: {
    expiryDate() {
      if (this.deposits && this.deposits.length > 0) {
        const lastDeposit = [...this.deposits].sort((a, b) => b.id - a.id)[0];
        const date = new Date(lastDeposit.created_at);
        date.setHours(48);
        return date.toDateString();
      }
      return new Date().toDateString();
    },
    fiatBuy() {
      if (this.deposits && this.deposits.length > 0) {
        const fiatDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() === 'usd');
        return fiatDeposits[fiatDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
    btcBuy() {
      if (this.deposits && this.deposits.length > 0) {
        const btcDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() !== 'usd');
        return btcDeposits[btcDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
    lastLoginDate() {
      if (this.userData) {
        const date = new Date(0);
        const seconds = client.token.id.data.auth_time;
        date.setUTCSeconds(seconds);
        return new Date(date).toUTCString();
      }
      return null;
    },
    totalCrypto() {
      if (this.trades && this.trades.length) {
        const total = this.trades.reduce((acc, el) => acc + parseFloat(el.amount_crypto), 0);
        return parseFloat(total).toFixed(5);
      }
      return 0;
    },
    name() {
      if (this.userData) {
        return `${this.userData.first_name} ${this.userData.last_name}`;
      }
      return '';
    }
  },
  watch: {
    darkMode(val) {
      if (val === true) {
        document.documentElement.className = null;
        localStorage.setItem('gc-theme', 'dark');
      } else {
        document.documentElement.className = 'theme--light';
        localStorage.setItem('gc-theme', 'light');
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem('gc-theme');
    this.darkMode = theme === 'dark';
    this.getBalance();
    this.getDeposits();
    this.getAccounts();
    this.getTrades();
    user.account().then((res) => {
      this.userData = res;
    });
  },
  methods: {
    getTrades() {
      api.trade.read().then((res) => {
        this.trades = res;
      });
    },
    getBalance() {
      api.balance().then((res) => {
        this.balance = res;
      });
    },
    getDeposits() {
      api.deposit.read().then((res) => {
        this.deposits = res;
        this.depositsLoaded = true;
      });
    },
    getAccounts() {
      api.account.read().then((res) => {
        this.accounts = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-profile__info h5 {
  display: inline-block;
}

.sub-menu {
  ul {
    @include screen('phone-land') {
      display: inline-block !important;
    }

    li {
      a {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        color: var(--body-color);

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--primary);
          background-color: transparent;
        }
      }

      &.active {
        a {
          color: var(--primary);
        }
      }

      i {
        font-weight: bold;
        margin-right: 7px;
        font-size: 20px;
      }

      span {
        font-weight: 400;
      }
    }
  }
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-color);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;

  h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: var(--body-color);

    span {
      // background: var(--primary);
      color: var(--primary);
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid var(--border-color);
      height: 40px;
      width: 40px;
      display: inline-block;
    }
  }

  .verified,
  .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    span {
      background: var(--success);
      color: var(--white);
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .not-verify {
    span {
      background: var(--danger);
    }
  }
}

// Security
.id_card {
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 20px 0px;
}

table {
  color: var(--text);
}
</style>
